import _implementation from "./implementation";

var _global = typeof globalThis !== "undefined" ? globalThis : typeof self !== "undefined" ? self : global;

var exports = {};
var implementation = _implementation;

exports = function getPolyfill() {
  if (typeof _global !== "object" || !_global || _global.Math !== Math || _global.Array !== Array) {
    return implementation;
  }

  return _global;
};

export default exports;